import React, { useContext } from 'react';

import {
	ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';

export const EditorPublishSuccessFlagErrorBoundary = (props) => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	return (
		<TransparentErrorBoundary
			attribution={Attribution.CC_ONBOARDING}
			onError={(error) => {
				experienceTracker.fail({
					name: ONBOARDING_EDITOR_PUBLISH_SUCCESS_FLAG_EXPERIENCE,
					error,
				});
			}}
		>
			{props.children}
		</TransparentErrorBoundary>
	);
};
