import type { FC } from 'react';
import React, { useContext } from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';

import { useWindowSize } from '@confluence/dom-helpers';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import type { ReactionLocation } from '@confluence/reactions';
import {
	DelayedReactions,
	useReactionsQueryParams,
	ReactionContainerType,
} from '@confluence/reactions';
import { ReactionsPlaceholder } from '@confluence/reactions/entry-points/ReactionsPlaceholder';
import { ReactionsSSRLoader } from '@confluence/reactions/entry-points/ReactionsSSRLoader';
import { ReactionsContext } from '@confluence/comment-context';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
} from '@confluence/custom-sites-extensions';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { useRediscoverReactionsExperiment } from '@confluence/experiment-rediscover-reactions';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { useInView } from 'react-intersection-observer';

export enum ReactionsPlacement {
	BYLINE = 'byline',
	FLOATING = 'floating',
}

const FloatingMinWindowWidth = 768;

const ViewPageReactionsContainerCommonStyles = xcss({
	display: 'flex',
	clear: 'both',
	overflow: 'visible',
	maxWidth: 'unset',
	margin: 'unset',
});

const ViewPageReactionsContainerBylineCenterStyles = xcss({
	justifyContent: 'center',
});

const ViewPageReactionsContainerFloatingStyles = xcss({
	alignItems: 'center',
	justifyContent: 'center',
	position: 'fixed',
	bottom: 'space.300',
	paddingRight: 'space.150',
	paddingLeft: 'space.150',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.raised',
	opacity: 1,
	transition: 'opacity 0.5s ease-in-out',
});

const ViewPageReactionsContainerHiddenStyles = xcss({
	opacity: 0,
});

const ReactionsFloatingStyles = xcss({
	marginBottom: 'space.050',
	marginRight: 'space.negative.250',
});

type ViewPageReactionsProps = {
	reactionsPlacement: ReactionsPlacement;
	contentId: string;
	contentType?: string | null;
	spaceId?: string | null;
	contentSubType?: string | null;
	isHidden?: boolean;
};

const ReactionsComponent: FC<ViewPageReactionsProps> = ({
	contentId,
	reactionsPlacement,
	contentType,
	spaceId,
	contentSubType,
	isHidden,
}) => {
	const [state] = useContentState();
	const { focusedPageReactionsId } = useReactionsQueryParams();
	const { edition } = useSessionData();
	const [reactionsRef, reactionsSectionInView] = useInView({
		rootMargin: '-100px 0px 0px 0px',
	});
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);

	const { titleContentProperties } = useTitleContentPropertiesForPublishedPage({
		contentId,
		isCustomSitesPageTitleFFOn,
	});

	const isBylineStyles = reactionsPlacement === ReactionsPlacement.BYLINE;
	const isBylineCenterStyles =
		isBylineStyles &&
		isCustomSitesPageTitleFFOn &&
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;
	const isFloatingStyles = reactionsPlacement === ReactionsPlacement.FLOATING;

	const displayReactions = () => {
		if (contentType && spaceId) {
			// We want to load in the background ONLY if we have a query param, otherwise we can preload the query
			const ReactionsLoader = Boolean(focusedPageReactionsId)
				? DelayedReactions
				: ReactionsSSRLoader;

			const summaryViewEnabled =
				reactionsPlacement === ReactionsPlacement.BYLINE ||
				reactionsPlacement === ReactionsPlacement.FLOATING;

			const summaryViewPlacement =
				reactionsPlacement === ReactionsPlacement.BYLINE ? 'bottom-start' : 'top-start';

			return (
				<Flex xcss={reactionsPlacement === ReactionsPlacement.FLOATING && ReactionsFloatingStyles}>
					<ReactionsLoader
						contentId={contentId}
						location={contentType as ReactionLocation}
						containerId={spaceId}
						containerType={ReactionContainerType.SPACE}
						contentSubType={contentSubType}
						summaryViewEnabled={summaryViewEnabled}
						animateNoReactionsText={summaryViewEnabled}
						customAnalyticsAttributes={{
							edition,
							reactionsPlacement,
						}}
						summaryViewPlacement={summaryViewPlacement}
					/>
				</Flex>
			);
		} else {
			return <ReactionsPlaceholder />;
		}
	};

	let reactionsComponent = <ReactionsPlaceholder />;
	if (!process.env.REACT_SSR && reactionsSectionInView && state?.isContentReady) {
		reactionsComponent = displayReactions();
	}

	let testId = 'view-page-reactions-experiment-container';
	if (isBylineCenterStyles) {
		testId = 'view-page-reactions-byline-center-experiment-container';
	} else if (isFloatingStyles) {
		testId = 'view-page-reactions-floating-experiment-container';
	} else if (isBylineStyles) {
		testId = 'view-page-reactions-byline-experiment-container';
	}

	return (
		<Box
			ref={reactionsRef}
			testId={testId}
			xcss={[
				ViewPageReactionsContainerCommonStyles,
				isBylineCenterStyles && ViewPageReactionsContainerBylineCenterStyles,
				isFloatingStyles && ViewPageReactionsContainerFloatingStyles,
				isHidden && ViewPageReactionsContainerHiddenStyles,
			]}
		>
			{reactionsComponent}
		</Box>
	);
};

const ViewPageReactionsComponent: FC<ViewPageReactionsProps> = (props) => {
	const { reactionsPlacement, isHidden } = props;
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const { width: windowWidth } = useWindowSize();

	const { isBylineCohort, isFloatingCohort } = useRediscoverReactionsExperiment({
		skip: !isReactionsEnabled,
	});

	if (
		isFloatingCohort &&
		reactionsPlacement === ReactionsPlacement.FLOATING &&
		(isHidden || windowWidth <= FloatingMinWindowWidth)
	) {
		return null;
	}

	if (
		(isBylineCohort && reactionsPlacement === ReactionsPlacement.BYLINE) ||
		(isFloatingCohort &&
			(reactionsPlacement === ReactionsPlacement.FLOATING ||
				reactionsPlacement === ReactionsPlacement.BYLINE))
	) {
		return <ReactionsComponent {...props} />;
	} else {
		return null;
	}
};

export const ViewPageReactionsExperiment: FC<ViewPageReactionsProps> = (props) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.NOTIFICATIONS}>
			<ViewPageReactionsComponent {...props} />
		</TransparentErrorBoundary>
	);
};
