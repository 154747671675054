import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useMemo } from 'react';

import { checkRevertFlag, RevertEditorSuccess } from '../EditorRevert';

export type AutoConversionContextValue = {
	editorProperty: string;
	fabricEditorSupported: boolean | null | undefined;
	queryDataId: string | null | undefined;
};

export const AutoConversionByLineContext = createContext<AutoConversionContextValue>({
	editorProperty: '',
	fabricEditorSupported: false,
	queryDataId: '',
});

type AutoConversionContextProviderProps = {
	editorProperty: string;
	fabricEditorSupported: boolean | null | undefined;
	queryDataId: string | null | undefined;
	children?: ReactNode;
};

export const AutoConversionByLineContextProvider: FC<AutoConversionContextProviderProps> = ({
	editorProperty,
	fabricEditorSupported,
	queryDataId,
	children,
}) => {
	const isAutoConverted = !editorProperty && fabricEditorSupported;
	const isFabric = editorProperty === 'v2' || isAutoConverted;

	const autoConversionContextValue = useMemo(
		() => ({
			editorProperty,
			fabricEditorSupported,
			queryDataId,
		}),
		[editorProperty, fabricEditorSupported, queryDataId],
	);

	return (
		<AutoConversionByLineContext.Provider value={autoConversionContextValue}>
			{children}
			{!isFabric && queryDataId && checkRevertFlag(queryDataId) && (
				<div data-testid="auto-editor-conversion-revert-success">
					<RevertEditorSuccess />
				</div>
			)}
		</AutoConversionByLineContext.Provider>
	);
};

export const useAutoConversionByLineContext = () => useContext(AutoConversionByLineContext);
