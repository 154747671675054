import { B300, P300, T300, R300, Y300, G300 } from '@atlaskit/theme/colors';

type Confetti = ((options?: confetti.Options) => Promise<undefined> | null) & {
	reset: () => void;
};

interface AnimationOptions {
	resetAfter?: number;
}

export const popOnboardingConfetti = async ({ resetAfter = 3000 }: AnimationOptions = {}) => {
	let confetti: Confetti | null = null;
	try {
		confetti = (await import(/* webpackChunkName: "loadable-canvas-confetti" */ 'canvas-confetti'))
			.default;
	} catch (error) {
		return;
	}

	if (!confetti) {
		return;
	}

	// mirrors the confetti animation in Atlas
	const baseProps = {
		particleCount: 120,
		spread: 55,
		ticks: 225,
		zIndex: 2000,
		scalar: 1.5,
		startVelocity: 100,
		colors: [P300, B300, T300, R300, Y300, G300],
	};

	// right corner
	void confetti({
		...baseProps,
		origin: {
			x: 1,
			y: 1,
		},
		angle: 120,
	});

	// left corner
	void confetti({
		...baseProps,
		origin: {
			x: 0,
			y: 1,
		},
		angle: 60,
	});

	if (resetAfter) {
		setTimeout(() => {
			confetti?.reset();
		}, resetAfter);
	}
};
