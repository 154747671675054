import { useContext } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

type ReactionsQueryParams = {
	focusedPageReactionsId?: string;
	focusedCommentReactionsId?: string;
	focusedPageQuickReactionsId?: string;
	focusedCommentQuickReactionsId?: string;
	quickReactionsEmojiId?: string;
	removeReactionsQueryParams: () => void;
};

export const useReactionsQueryParams = (): ReactionsQueryParams => {
	const { getQueryParams, setQueryParams } = useContext(RoutesContext);

	const { action, contentId, contentType, focusedCommentId, emojiId } = getQueryParams();

	// Query params look like this for page & blogpost: action=react&contentId=123&contentType=page
	// Query params look like this for comment: action=react&focusedCommentId=123
	// Query params look like this for comment (page and inline) quick reactions: action=addReaction&focusedCommentId=123&&emojiId=1f44d
	// Query params look like this for page & blogpost quick reactions: action=addReaction&contentId=123&contentType=page&emojiId=1f48e

	const reaction = action && action === 'react';
	const quickReaction = action && action === 'addReaction';

	const focusedPageReactionsId =
		reaction && contentType && (contentType === 'page' || contentType === 'blogpost') && contentId
			? (contentId as string)
			: undefined;
	const focusedCommentReactionsId =
		reaction && focusedCommentId ? (focusedCommentId as string) : undefined;

	const quickReactionsEmojiId = quickReaction && emojiId ? (emojiId as string) : undefined;
	const focusedCommentQuickReactionsId =
		quickReactionsEmojiId && focusedCommentId ? (focusedCommentId as string) : undefined;

	const focusedPageQuickReactionsId =
		quickReaction &&
		quickReactionsEmojiId &&
		contentType &&
		(contentType === 'page' || contentType === 'blogpost') &&
		contentId
			? (contentId as string)
			: undefined;

	const removeReactionsQueryParams = () => {
		if (action && emojiId) {
			const queryParams = getQueryParams();
			setQueryParams({
				...queryParams,
				action: undefined,
				emojiId: undefined,
				contentType: undefined,
				contentId: undefined,
			});
		}
	};

	return {
		focusedPageReactionsId,
		focusedCommentReactionsId,
		focusedCommentQuickReactionsId,
		focusedPageQuickReactionsId,
		quickReactionsEmojiId,
		removeReactionsQueryParams,
	};
};
