import React from 'react';

import { EditorPublishSuccessFlag } from '@confluence/onboarding-editor';

type SatisfactionSurveysProps = {
	contentId: string;
	isEditorExpEnabled: boolean;
};

export const SatisfactionSurveys = ({
	contentId,
	isEditorExpEnabled,
}: SatisfactionSurveysProps) => (
	<>
		{isEditorExpEnabled && (
			// this determines whether to show the success flag based on our FFs
			<EditorPublishSuccessFlag contentId={contentId} />
		)}
	</>
);
