import { LoadableLazy } from '@confluence/loadable';

export const AIFloatingContextMenu = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AIFloatingContextMenu" */ './AIFloatingContextMenu'
			)
		).AIFloatingContextMenu,
});
