import React, { createContext, useContext, useState, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import { PageCommentsCountContext } from './PageCommentsCountContext';
import type { PositionalComment } from './commentContextTypes';

/*
 *  NOTE: This context is only for page comments as of now to work
 *  alongside the pubsub library to help auto-reload page comments
 */

export type AddCommentType = {
	currentUserMadeComment: boolean;
	newCommentId?: string;
};

type NewCommentsContext = {
	newCommentPositions: PositionalComment[];
	addNewComment: ({ currentUserMadeComment, newCommentId }: AddCommentType) => void;
	removeComment: (commentId?: string) => void;
	acknowledgeComment: (commentId?: string) => void;
	acknowledgeComments: (commentIds?: string[]) => void;
	clearNewComments: () => void;
};

export const NewCommentsContext = createContext<NewCommentsContext>({
	newCommentPositions: [],
	addNewComment: () => {},
	removeComment: () => {},
	acknowledgeComment: () => {},
	acknowledgeComments: () => {},
	clearNewComments: () => {},
});

const getCommentScreenPosition = (commentId: string) => {
	const commentElem = document.querySelector(`#comment-${commentId}`) as HTMLElement | null;

	if (commentElem) {
		return {
			top: commentElem.offsetTop,
			bottom: commentElem.offsetTop + commentElem.offsetHeight,
		};
	}

	return { top: Number.NEGATIVE_INFINITY, bottom: Number.POSITIVE_INFINITY };
};

type NewCommentsProviderProps = {
	children: ReactNode;
};

export const NewCommentsProvider: FC<NewCommentsProviderProps> = ({ children }) => {
	const [newCommentPositions, setNewCommentPositions] = useState<PositionalComment[]>([]);
	const { count, setCount } = useContext(PageCommentsCountContext);

	const newCommentsContextValue = useMemo(
		() => ({
			newCommentPositions,
			addNewComment: ({ currentUserMadeComment, newCommentId }: AddCommentType) => {
				if (newCommentId) {
					const pos = getCommentScreenPosition(newCommentId);
					setNewCommentPositions((existingPositions) => {
						const commentExists = existingPositions.some((e) => e.id === newCommentId);
						return !commentExists
							? [
									...existingPositions,
									{
										id: newCommentId,
										pos,
										seen: false,
										currentUserMadeComment,
									},
								]
							: existingPositions;
					});

					// Update the comment count
					setCount(count + 1);
				}
			},
			removeComment: (commentId?: string) => {
				if (commentId) {
					const idx = newCommentPositions.findIndex((comment) => commentId === comment.id);

					if (idx !== -1) {
						const positionCopy = [...newCommentPositions];
						positionCopy.splice(idx, 1);
						setNewCommentPositions(positionCopy);
					}
					// Update the comment count
					const newCount = count - 1;
					setCount(newCount < 0 ? 0 : newCount);
				}
			},
			acknowledgeComment: (commentId?: string) => {
				if (commentId) {
					const comment = newCommentPositions.find(
						(commentPosition) => commentPosition.id === commentId,
					);

					if (comment) {
						comment.seen = true;
					}
				}
			},
			acknowledgeComments: (commentIds?: string[]) => {
				if (commentIds && commentIds.length) {
					commentIds.forEach((commentId) => {
						const comment = newCommentPositions.find(
							(commentPosition) => commentPosition.id === commentId,
						);

						if (comment) {
							comment.seen = true;
						}
					});
				}
			},
			clearNewComments: () => {
				setNewCommentPositions([]);
			},
		}),
		[count, newCommentPositions, setCount],
	);

	return (
		<NewCommentsContext.Provider value={newCommentsContextValue}>
			{children}
		</NewCommentsContext.Provider>
	);
};
