import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import type { FlagsStateContainer, WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { removeRevertFlag } from './helpers/storageHelper';

const i18n = defineMessages({
	revertEditorFlagSuccessTitle: {
		id: 'editor-conversion.revert-editor.flag.success.title',
		defaultMessage: 'Editor reverted',
		description: 'Title for the flag notifying user that we successfully reverted the target page',
	},
	revertEditorFlagSuccessBody: {
		id: 'editor-conversion.revert-editor.flag.success.body',
		defaultMessage: 'It was successfully reverted to the legacy editor for this page.',
		description: 'Body for the flag notifying user that we successfully reverted the target page',
	},
});

type RevertEditorSuccessProps = WithFlagsProps & {
	flags: FlagsStateContainer;
};

export const RevertEditorSuccessComponent: FC<RevertEditorSuccessProps> = ({ flags }) => {
	useEffect(() => {
		const description = <FormattedMessage {...i18n.revertEditorFlagSuccessBody} />;

		void flags.showFlag({
			title: <FormattedMessage {...i18n.revertEditorFlagSuccessTitle} />,
			description,
			close: 'auto',
		});

		removeRevertFlag();
	}, [flags]);

	return null;
};

export const RevertEditorSuccess = withFlags(RevertEditorSuccessComponent);
