import type React from 'react';
import { useEffect, useContext, useRef } from 'react';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';

import { scrollToPageStart } from './scrollToElement';

/**
 * This component serves a purpose of "resetting" page scroll to (0, 0).
 * Use it where you would have used `window.scrollTo(0, 0)` otherwise.
 * It is aware of Confluence-specific scroll logic, so it will do its best
 * to avoid multiple scrolls on the same page.
 *
 * The scrolling only happens during the first time this component is mounted.
 * If you want to repeat this action depending on the change in your component
 * props, use `"key"` for that, e.g.
 *
 * ```js
 *  const Content = (props) => {
 *    return (
 *      <React.Fragment>
 *        {
 *        // will scroll to page start every time "contentId" prop changes
 *        }
 *        <ScrollToPageStart key={props.contentId} />
 *        <ContentBody contentId={props.contentId} />
 *      </React.Fragment>
 *    )
 *  }
 * ```
 */
export const ScrollToPageStart: React.FC<{ isSmooth?: boolean }> = ({ isSmooth }) => {
	const { getHash, getQueryParams } = useContext(RoutesContext);

	// we're using refs here to avoid violating the rules
	// of hooks, but still prevent ourselves from
	// scrolling to page start multiple times in case if
	// `RoutesContext` changes unexpectedly -- if that happens,
	// we'll just take the latest context values into account
	// when executing scroll, and ignore everything we've seen
	// prior to that.
	const getHashRef = useRef(getHash);
	const getQueryParamsRef = useRef(getQueryParams);

	getHashRef.current = getHash;
	getQueryParamsRef.current = getQueryParams;

	useEffect(() => {
		const hash = getHashRef.current();
		const queryParams = getQueryParamsRef.current();

		const queryParamsThatTriggerScroll = ['focusedCommentId'];

		const doesPageHaveExplicitScrollTarget =
			hash.length > 0 ||
			queryParamsThatTriggerScroll.some((paramName) => Boolean(queryParams[paramName]));
		if (!doesPageHaveExplicitScrollTarget) {
			scrollToPageStart(isSmooth);
		}
	}, [getHashRef, getQueryParamsRef, isSmooth]);

	return null;
};
