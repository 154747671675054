import { useCallback } from 'react';
import { useApolloClient } from 'react-apollo';

import type { LocalActivityAction } from './__types__/LocalActivityQuery';
import { trackLocalActivity } from './useLocalActivity';

interface LocalActivityTracker {
	track(contentId: string, action: LocalActivityAction): void;
}

export const useLocalActivityTracker = (): LocalActivityTracker => {
	const apolloClient = useApolloClient();

	const track = useCallback(
		(contentId: string, action: LocalActivityAction) => {
			trackLocalActivity({ contentId, action }, apolloClient);
		},
		[apolloClient],
	);

	return { track };
};
