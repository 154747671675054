import { LoadableAfterPaint } from '@confluence/loadable';

export const EndOfPageRecommendation = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EndOfPageRecommendation" */ './EndOfPageRecommendation'
			)
		).EndOfPageRecommendation,
});

export const EndOfPageRecAdminConfig = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EndOfPageRecAdminConfig" */ './EndOfPageRecAdminConfig'
			)
		).EndOfPageRecAdminConfig,
});

export { EndOfPageHideConfirmModal } from './EndOfPageHideConfirmModal';
export { EndOfPageRecHideStatusMutation } from './EndOfPageRecHideStatusMutation.graphql';
export { EndOfPageRecommendationQuery } from './EndOfPageRecommendationQuery.graphql';
