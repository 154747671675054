import type { FC } from 'react';
import React from 'react';

import { LoadablePaint, LoadableAfterPaint, LoadingPriority } from '@confluence/loadable';
import { CommentPlaceholder } from '@confluence/comment-simple/entry-points/CommentPlaceholder';

import type { CommentsSectionProps } from './CommentsSection';

const CommentsSectionLoaderPaint = LoadablePaint({
	loading: ({ handlePageCommentsRender }) => {
		return !handlePageCommentsRender ? (CommentPlaceholder as any) : null;
	},
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentsSection" */ './CommentsSection'))
			.CommentsSection,
});

const CommentsSectionLoaderAfterPaint = LoadableAfterPaint({
	loading: () => <CommentPlaceholder />,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentsSection" */ './CommentsSection'))
			.CommentsSection,
});

const loaders = {
	[LoadingPriority.PAINT]: CommentsSectionLoaderPaint,
	[LoadingPriority.AFTER_PAINT]: CommentsSectionLoaderAfterPaint,
};

export const CommentsSectionLoader: FC<
	CommentsSectionProps & { loadingPriority: LoadingPriority }
> = ({ loadingPriority, ...rest }) => {
	const PageCommentsLoader = loaders[loadingPriority] || CommentsSectionLoaderAfterPaint;

	return <PageCommentsLoader {...rest} />;
};
