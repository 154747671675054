import type { FC } from 'react';
import { useEffect } from 'react';

import { LocalActivityAction } from './__types__/LocalActivityQuery';
import { useLocalActivityTracker } from './useLocalActivityTracker';

export const LocalActivityViewPageTracker: FC<{ contentId: string }> = ({ contentId }) => {
	useLocalActivityViewPageTracker(contentId);
	return null;
};

const useLocalActivityViewPageTracker = (contentId: string) => {
	const { track } = useLocalActivityTracker();
	useEffect(() => {
		track(contentId, LocalActivityAction.viewed);
	}, [track, contentId]);
};
