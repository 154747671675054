import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import type { FC, ReactElement } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	BannerContext,
	BannerStateContainer,
	FloatingBannerStateContainer,
} from '@confluence/banners';
import { NewCommentsContext } from '@confluence/comment-context';
import type { PositionalComment } from '@confluence/comment-context';
import { scrollPageCommentIntoView } from '@confluence/comments-util';

import { PillOverlayWrapper, ContentWrapper } from './styled-components';
import { useIsPageCommentsAutoReloadEnabled } from './feature-flags';
import { CommentsPill } from './CommentsPill';

type CommentsPillOverlayProps = {
	contentId: string;
	children: ReactElement;
	inView: boolean;
};

// We just need to offset the top of the viewport so we don't get things below the fold
const SCROLL_OFFSET = 40;

export const CommentsPillOverlay: FC<CommentsPillOverlayProps> = ({
	contentId,
	children,
	inView,
}) => {
	const { newCommentPositions, acknowledgeComment, acknowledgeComments, clearNewComments } =
		useContext(NewCommentsContext);
	const { includeTopNav } = useContext(BannerContext);
	const [commentsAbove, setCommentsAbove] = useState<PositionalComment[]>([]);
	const [commentsBelow, setCommentsBelow] = useState<PositionalComment[]>([]);
	const scrollTimeoutHandle = useRef<NodeJS.Timeout | null>(null);
	const isCommentsAutoReloadEnabled = useIsPageCommentsAutoReloadEnabled();

	// For analytics
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isPillShown = useRef(false);
	const prevCommentsCount = useRef(0);

	const recalculatePositions = useCallback(() => {
		if (newCommentPositions.length === 0) {
			setCommentsAbove([]);
			setCommentsBelow([]);
			return;
		}

		// Current user scroll position
		const topScroll = window.scrollY + SCROLL_OFFSET;
		const bottomScroll = topScroll + window.innerHeight - SCROLL_OFFSET;

		setCommentsAbove(
			newCommentPositions.filter(
				(commentPosition) =>
					!commentPosition.seen &&
					commentPosition.pos.bottom < topScroll &&
					!commentPosition.currentUserMadeComment,
			),
		);
		setCommentsBelow(
			newCommentPositions.filter(
				(commentPosition) =>
					!commentPosition.seen &&
					commentPosition.pos.top > bottomScroll &&
					!commentPosition.currentUserMadeComment,
			),
		);
	}, [newCommentPositions]);

	const markCommentsSeen = useCallback(() => {
		if (newCommentPositions.length === 0) {
			return;
		}

		// Current user scroll position
		const topScroll = window.scrollY + SCROLL_OFFSET;
		const bottomScroll = topScroll + window.innerHeight - SCROLL_OFFSET;

		const commentsInView = newCommentPositions.filter(
			(commentPosition) =>
				!commentPosition.seen &&
				commentPosition.pos.bottom > topScroll &&
				commentPosition.pos.top < bottomScroll,
		);

		if (commentsInView.length) {
			acknowledgeComments(commentsInView.map((comment) => comment.id));
		}
	}, [newCommentPositions, acknowledgeComments]);

	const handleScroll = useCallback(() => {
		if (scrollTimeoutHandle.current) {
			clearTimeout(scrollTimeoutHandle.current);
		}

		scrollTimeoutHandle.current = setTimeout(() => {
			recalculatePositions();
			markCommentsSeen();
		}, 100);
	}, [recalculatePositions, markCommentsSeen]);

	useEffect(() => {
		if (isCommentsAutoReloadEnabled) {
			window.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (isCommentsAutoReloadEnabled) {
				window.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll, isCommentsAutoReloadEnabled]);

	useEffect(() => {
		// Clear the new comments when a new contentId mounts
		return () => {
			clearNewComments();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	// Track comments pill displaying, don't send a duplicate event if the pill is already shown
	useEffect(() => {
		const newPageCommentsCount =
			commentsAbove.length > 0 ? commentsAbove.length : commentsBelow.length;
		if (inView && (!isPillShown.current || newPageCommentsCount > prevCommentsCount.current)) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'viewPageScreen',
					action: 'displayed',
					actionSubject: 'pageCommentsPill',
					attributes: {
						newPageCommentsCount,
					},
				},
			}).fire();

			isPillShown.current = true;
			prevCommentsCount.current = newPageCommentsCount;
		} else if (!inView || (commentsAbove.length === 0 && commentsBelow.length === 0)) {
			// If pill is not in view or there are no new comments, reset
			isPillShown.current = false;
			prevCommentsCount.current = 0;
		}
	}, [inView, createAnalyticsEvent, commentsAbove.length, commentsBelow.length]);

	useLayoutEffect(() => {
		if (isCommentsAutoReloadEnabled) {
			recalculatePositions();
		}
	}, [recalculatePositions, isCommentsAutoReloadEnabled]);

	const handleClick = useCallback(
		(isAbove = false) => {
			const commentId = isAbove ? commentsAbove[0]?.id : commentsBelow[0]?.id;

			if (commentId) {
				const commentElem = document.querySelector(`#comment-${commentId}`) as HTMLElement | null;

				if (commentElem) {
					scrollPageCommentIntoView(commentElem, false, isAbove);
					acknowledgeComment(commentId);
				}
			}

			// Track comments pill click
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'viewPageScreen',
					action: 'clicked',
					actionSubject: 'pageCommentsPill',
					attributes: {
						newPageCommentsCount: isAbove ? commentsAbove.length : commentsBelow.length,
					},
				},
			}).fire();
		},
		[commentsAbove, commentsBelow, acknowledgeComment, createAnalyticsEvent],
	);

	return isCommentsAutoReloadEnabled ? (
		<Subscribe to={[BannerStateContainer, FloatingBannerStateContainer]}>
			{(bannerState: BannerStateContainer, floatingBanners: FloatingBannerStateContainer) => {
				const bannerHeight =
					bannerState.getTotalHeight(includeTopNav) + floatingBanners.getFloatingHeight();

				return (
					<PillOverlayWrapper>
						{inView && (
							<CommentsPill
								comments={commentsAbove}
								handleClick={() => handleClick(true)}
								offset={bannerHeight}
								name="new-comment-pill-top"
								isTop
							/>
						)}
						<ContentWrapper>{children}</ContentWrapper>
						{inView && (
							<CommentsPill
								comments={commentsBelow}
								handleClick={() => handleClick(false)}
								name="new-comment-pill-bottom"
							/>
						)}
					</PillOverlayWrapper>
				);
			}}
		</Subscribe>
	) : (
		children
	);
};
