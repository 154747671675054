import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { ReactionsPlaceholder } from '../src/ReactionsPlaceholder';

export const ReactionsSSRLoader = LoadableAfterPaint({
	loading: () => <ReactionsPlaceholder />,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Reactions" */ '../src/Reactions')).Reactions,
});
