import gql from 'graphql-tag';

export const ContentSubTypeAndStatusQuery = gql`
	query ContentSubTypeAndStatusQuery($contentId: ID!, $versionOverride: Int) @SLA {
		content(id: $contentId, version: $versionOverride) {
			nodes {
				id
				status
				subType
			}
		}
	}
`;
