import { LoadableLazy } from '@confluence/loadable';

export const AutomationPremiumTipLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AutomationPremiumTipLoader" */ '../src/BiteSizeOnboarding/AutomationPremiumTip'
			)
		).AutomationPremiumTip,
});
