import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

export const checkRevertFlag = (contentId: string) => {
	return sessionStorage?.getItem(localStorageKeys.EDITOR_REVERTED) === contentId;
};

export const addRevertFlag = (contentId: string) => {
	sessionStorage?.setItem(localStorageKeys.EDITOR_REVERTED, contentId);
};

export const removeRevertFlag = () => {
	sessionStorage?.removeItem(localStorageKeys.EDITOR_REVERTED);
};
