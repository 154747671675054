import React from 'react';
import { styled } from '@compiled/react';

import { LoadableAfterPaint } from '@confluence/loadable';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';

import { VIEW_PAGE_LABELS_METRIC } from './perf.config';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelsSelectPlaceholder = styled.div({
	width: '100%',
	height: '40px',
});

const ViewPageLabelsLoaderBase = LoadableAfterPaint({
	loading: () => <LabelsSelectPlaceholder />,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ViewPageLabels" */ './ViewPageLabels'))
			.ViewPageLabels,
});

export const ViewPageLabelsLoader = (props) => (
	<>
		<PageSegmentLoadStart metric={VIEW_PAGE_LABELS_METRIC} />
		<ViewPageLabelsLoaderBase {...props} />
	</>
);
