import React, { useState, useEffect, useContext } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay, isUnauthorizedError } from '@confluence/error-boundary';
import {
	EDITOR_ONBOARDING_STATE_KEYS,
	CONTEXTUAL_INVITE_NUDGE,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useCheckIfOnboardingEditorTemplate } from '@confluence/onboarding-helpers/entry-points/hooks/useCheckIfOnboardingEditorTemplate';
import {
	deserializeState,
	useGetOnboardingState,
	useOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import {
	suppressInfiSpotlight,
	resetInfiSpotlightValue,
} from '@confluence/onboarding-helpers/entry-points/infiSpotlightUtils';
import { FlagsStateContainer } from '@confluence/flags';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { useSessionData } from '@confluence/session-data';
import { useContextualInviteNudge } from '@confluence/onboarding-helpers/entry-points/hooks/useContextualInviteNudge';
import { popOnboardingConfetti } from '@confluence/onboarding-helpers/entry-points/popOnboardingConfetti';
import { CoreInvitesContext, CoreInvitesSource } from '@confluence/core-invites-provider';

import { EditorPublishSuccessFlag } from './EditorPublishSuccessFlag';
import { useOnboardingEditorState } from './useOnboardingEditorState';

export const EditorPublishSuccessFlagWrapper = ({ contentId }) => {
	const [isVisible, setVisible] = useState(false);
	const {
		data,
		error: onboardingStateError,
		loading: onboardingStateLoading,
	} = useGetOnboardingState(Object.values(EDITOR_ONBOARDING_STATE_KEYS));
	const { isExternalCollaborator } = useIsExternalCollaborator();
	const { isLicensed, isLoggedIn } = useSessionData();
	const { hasMetPublishNudgeConditions } = useOnboardingEditorState();
	const { setOnboardingState } = useOnboardingState();
	const { openCoreInvites } = useContext(CoreInvitesContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isShowingContextualInviteNudge, setIsShowingContextualInviteNudge] = useState(false);

	const {
		// Page publish success flag states
		editorOnboardingEligiblity,
		hasUserPublished,
		successFlagCompleted,
		// EO2 States
		editorTutorialSuccessFlagCompleted,
	} = deserializeState(data);

	const { isOnboardingTemplate, labelsData, labelsLoading, labelsError } =
		useCheckIfOnboardingEditorTemplate({
			contentId,
			skip:
				!isLicensed || !isLoggedIn || isExternalCollaborator || editorTutorialSuccessFlagCompleted,
		});

	const pagePublishConditions =
		editorOnboardingEligiblity &&
		hasUserPublished &&
		!successFlagCompleted &&
		!onboardingStateError;

	// EO2 FF eligibility is inferred if we get labelsData back
	const EO2Conditions =
		!labelsLoading &&
		Boolean(labelsData) &&
		isOnboardingTemplate &&
		!editorTutorialSuccessFlagCompleted &&
		!onboardingStateError;

	const { contextualInviteNudgeEligibleTag, onboardingState } = useContextualInviteNudge();
	const { wasFirstPublishConfettiSeen, wasLivePageNudgeSeen } = onboardingState;

	const showContextualInviteNudge =
		contextualInviteNudgeEligibleTag &&
		hasMetPublishNudgeConditions &&
		!wasFirstPublishConfettiSeen &&
		// deconflict with live pages contextual nudge
		!wasLivePageNudgeSeen;

	useEffect(() => {
		if (showContextualInviteNudge) {
			void popOnboardingConfetti();
			void setOnboardingState({
				key: CONTEXTUAL_INVITE_NUDGE.WAS_CONFETTI_SEEN,
				value: 'true',
			});

			// hide the infi spotlight
			suppressInfiSpotlight();

			// overlaps with the confetti
			setTimeout(() => {
				openCoreInvites?.(CoreInvitesSource.CONTEXTUAL_INVITE_NUDGE, {
					onModalClosed: () => {
						resetInfiSpotlightValue();
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								source: CoreInvitesSource.CONTEXTUAL_INVITE_NUDGE,
								action: 'closed',
								actionSubject: 'inviteModal',
								actionSubjectId: 'inviteModalCancelButton',
							},
						}).fire();
					},
					onInviteCompleted: () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								source: CoreInvitesSource.CONTEXTUAL_INVITE_NUDGE,
								action: 'completed',
								actionSubject: 'inviteModal',
								actionSubjectId: 'inviteModalInviteButton',
							},
						}).fire();
					},
					getContextualInviteNudgeProps: () => {
						return { showSpotlight: true };
					},
				});

				void setOnboardingState({
					key: EDITOR_ONBOARDING_STATE_KEYS.SUCCESS_FLAG_SEEN,
					value: 'true',
				});
			}, 2000);

			setIsShowingContextualInviteNudge(true);
		}
	}, [showContextualInviteNudge, openCoreInvites, setOnboardingState, createAnalyticsEvent]);

	const ErrorDisplays = () => (
		<>
			{onboardingStateError && <ErrorDisplay error={onboardingStateError} />}
			{labelsError && !isUnauthorizedError(labelsError) && <ErrorDisplay error={labelsError} />}
		</>
	);

	useEffect(() => {
		// Many onboarding features are currently disabled for ExCo users
		// Disabling the CTA to share the page a user had just published, if it's their first time. This may change (ref: MDS-1573)
		// Note: the other flags inside EditorPublishSuccessFlag deal with the admins or the editor tutorial,
		// both of which do not apply to ExCo users at this time.
		if (!isExternalCollaborator && (pagePublishConditions || EO2Conditions)) {
			setVisible(true);
		}
	}, [isExternalCollaborator, pagePublishConditions, EO2Conditions]);

	if (
		labelsLoading ||
		onboardingStateLoading ||
		// both conditions below are for Contextual Invite Nudge
		showContextualInviteNudge ||
		isShowingContextualInviteNudge
	) {
		return null;
	}

	if (isVisible) {
		// Show EO2 Flag
		const showEditorTutorialPublishSuccessFlag =
			EO2Conditions && !editorTutorialSuccessFlagCompleted && isOnboardingTemplate;

		return (
			<Subscribe to={[FlagsStateContainer]}>
				{(flags: FlagsStateContainer) => (
					<>
						{labelsError && !isUnauthorizedError(labelsError) && (
							<ErrorDisplay error={labelsError} />
						)}
						<EditorPublishSuccessFlag
							flags={flags}
							showEditorTutorialPublishSuccessFlag={showEditorTutorialPublishSuccessFlag}
						/>
					</>
				)}
			</Subscribe>
		);
	}

	return <ErrorDisplays />;
};
