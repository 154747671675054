import React from 'react';

import { EditorPublishSuccessFlagWrapper } from './EditorPublishSuccessFlagWrapper';
import { EditorPublishSuccessFlagErrorBoundary } from './EditorPublishSuccessFlagErrorBoundary';

export const EditorPublishSuccessFlag = (
	props: React.ComponentProps<typeof EditorPublishSuccessFlagWrapper>,
) => (
	<EditorPublishSuccessFlagErrorBoundary>
		<EditorPublishSuccessFlagWrapper {...props} />
	</EditorPublishSuccessFlagErrorBoundary>
);

export { OnboardingEditorBanner } from './OnboardingEditorBanner';
export { OnboardingEditorModalPrompt } from './EditorModalPrompt';
